import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import styled from "styled-components"
import Layout from "../layouts"
import SEO from "../components/seo"
import Heading from "../components/Heading"

const AboutText = styled.p`
  max-width: 1000px;
  text-align: center;
  margin: 100px 0;
  padding: 0 10px;
  font-size: 1.5rem;
  line-height: 2.5rem;
`

const SecondPage = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     atMachine: file(relativePath: { eq: "DSC00710.JPG" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1200, quality: 90) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Layout>
      <SEO title="About" />
      <Heading>About</Heading>
      <AboutText>
        Long Time Gone creates beautiful, custom embroidery using vintage
        chainstitch machines and techniques.
        <br />
        <br />
        Beginning with hand-embroidered Western wear in 2017, owner, designer
        and stitcher Kellye Coffyn brought the styles of her native Texas to the
        UK, inspired by country music culture, the embroidered suits worn by
        performers like Dolly Parton and Hank Williams and the beautiful natural
        surroundings of her adopted home of Yorkshire.
        <br />
        <br />
        Every design is hand drawn and each stitch positioned by hand - nothing
        is computerised, digitised or mass produced. Before LTG, Kellye spent
        nearly 20 years in academia, primarily as a research physicist. Having
        had a love for textile arts and making clothing since childhood, as well
        as a fascination with tinkering with machines, she now combines the
        Southwestern aesthetics of her youth with her analytical training and
        precise attention to detail to create beautiful, one-of-a-kind wearable
        works of art.
        <br />
        <br />
        With Long Time Gone, sustainability is a main priority, and deadstock
        materials and vintage garments are used to help ensure your
        one-of-a-kind garment is also kind to the earth.
      </AboutText>
    </Layout>
  )
}

export default SecondPage
